<template>
  

  <div class="grid">
    <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#274461"
    :is-full-page="fullPage"
  ></loading>
    <div class="col-12">
      <div class="card">
        <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
             
              <Calendar
                  v-model="from_date"
                  dateFormat="dd-mm-yy"
                  :showIcon="true"
                  :showTime="false"
                 
                  hourFormat="12"
                  label="From Date"
                /> <label for="from_date">From Date</label>
              </span>
              </div> &nbsp;&nbsp;
                <div class="my-2">
                  <span class="p-float-label">
                
                <Calendar
                  v-model="to_date"
                  dateFormat="dd-mm-yy"
                  :showIcon="true"
                  :showTime="false"
                  
                  hourFormat="12"
                /><label for="to_date">To Date</label>
              </span>

            </div>&nbsp;&nbsp;
            <div class="my-2">
              <span class="p-float-label">
              
              <InputText type="text" v-model="search_mobile" /><label for="mobile_no">Mobile No.</label>
            </span>
            </div>&nbsp;&nbsp;
            <div class="my-2">
              <span class="p-float-label">
              
              <Dropdown style="width:150px" id="state" filter v-model="ride_status" :options="ride_status_dropdown" optionLabel="name" :optionValue="name"></Dropdown>
              <label for="Status">Status</label>
              <!-- <select
                 
                  v-model="ride_status"
                >
                  <option value="">Select Status</option>
                  <option value="1">Read</option>
                  <option value="0">Unread</option>
                </select> -->
              </span>
            </div>
            &nbsp;&nbsp;
            <div class="my-2">
              <span class="p-float-label">
              
              <Button
                  icon="pi pi-search"
                  
                  v-tooltip.bottom="'Search'"
                  @click="search(1)"   
                /><label for="Status"></label>
              </span>
              </div>
          </template>

          <template v-slot:end> 
            <div class="my-2">
              
            <Button icon="pi pi-plus" @click="add_notification()" v-tooltip.bottom="'Add'"/>
            </div>
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true"
          showGridlines
        >
          <template #empty>
            <b style="text-align: center">No records found.</b>
          </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Manage Push Notification Master</b></h5>
              <!-- Total Records:{{ totalRecords }} -->
              

              <Button icon="pi pi-refresh" @click="search(2)"   v-tooltip.bottom="'Refresh'"/>

              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div><div style="text-align:right;font-weight:bold"><span>Total Notifications:</span>{{totalRecords}}</div>
            <!-- <br />

            <div class="flex flex-column md:flex-row">
              <div style="padding-right: 10px">From Date</div>
              <div style="padding-right: 10px">
                <Calendar
                  v-model="from_date"
                  dateFormat="dd-mm-yy"
                  :showIcon="true"
                  :showTime="false"
                  style="height: 20px"
                  hourFormat="12"
                />
              </div>
              <div style="padding-right: 10px">To Date</div>
              <div style="padding-right: 10px">
                <Calendar
                  v-model="to_date"
                  dateFormat="dd-mm-yy"
                  :showIcon="true"
                  :showTime="false"
                  style="height: 20px"
                  hourFormat="12"
                />
              </div>
              <div style="padding-right: 10px">Mobile Number</div>
              <div style="padding-right: 10px">
                <input type="text" v-model="search_mobile" />
              </div>
              <div style="padding-right: 10px; width: 40px">Status</div>
              <div style="padding-right: 10px">
                <select
                  style="width: 100px; height: 20px"
                  v-model="ride_status"
                >
                  <option value="">Select Status</option>
                  <option value="1">Read</option>
                  <option value="0">Unread</option>
                </select>
              </div>
              <div style="padding-right: 10px">
                <Button
                  style="height: 20px"
                  icon="pi pi-search"
                  @click="search()"
                />
              </div>
            </div> -->
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column
            header="Status"
            style="min-width: 8rem; text-align: center"
            :field="columns[14]"
          >
            <template #body="{ data }">
              <span v-if="data.status == '1'"
                ><badge value="Read" severity="success"></badge
              ></span>

              <span v-else>Unread</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 5rem; text-align: center">
            <template #body="{ index }">
              <span v-if="page_no == 1">{{ +index + +1 }}</span>
              <span v-else>{{ +index + 1 + limit * (page_no - 1) }}</span>
            </template>
          </Column>

          <Column
            header="Date"
            :field="columns[9]"
            style="text-align: left; width: 100px"
          >
            <template #body="{ data }">
              {{ data.date }} {{ data.time }}
            </template>
          </Column>
          <Column header="User Name" style="min-width: 5rem">
            <template #body="{ data }">
              <p v-if="data.userdetails">
                {{ data.userdetails.first_name }}
                {{ data.userdetails.last_name }}
              </p>
            </template>
          </Column>

          <Column
            header="User Number"
            :field="columns[3]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              <p v-if="data.userdetails">
                {{ data.userdetails.mobile_number }}
              </p>
            </template>
          </Column>
          <!-- <Column header="Driver Name" :field="columns[4]" style="min-width: 10rem">
            <template #body="{ data }">
              <p v-if="data.driverdetails"> {{ data.driverdetails.first_name }} {{ data.driverdetails.last_name }}</p>
            </template>
          </Column>
          <Column header="Driver Mobile" :field="columns[4]">
            <template #body="{ data }">
                <p v-if="data.driverdetails"> {{ data.driverdetails.mobile_number }}</p>            </template>
          </Column> -->
          <Column
            header="Title"
            :field="columns[12]"
            style="text-align: left; width: 250px"
          >
            <template #body="{ data }">
              {{ data.title }}
            </template>
          </Column>
          <Column
            header="Description"
            :field="columns[9]"
            style="text-align: left; width: 350px"
          >
            <template #body="{ data }">
              {{ data.description }}
            </template>
          </Column>
          <Column header="Type" :field="columns[6]" style="width: 150px">
            <template #body="{ data }">
              {{ data.type }}
            </template>
          </Column>
          <!-- <Column header="Customer Feedback" :field="columns[10]">
            <template #body="{ data }">
              {{ dateFormat(data.customer_feedback) }}
            </template>
          </Column> -->

          <Column header="Action" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <!-- <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              /> -->
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>User Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>

                <div class="field col-12 md:col-2">
                  <label for="firsT_NAME">First Name</label>
                  <InputText
                    id="firsT_NAME"
                    type="text"
                    v-model.trim="product.firsT_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.firsT_NAME }"
                  />
                </div>

                <div class="field col-12 md:col-2">
                  <label for="middeL_NAME">Middle Name</label>
                  <InputText
                    id="middeL_NAME"
                    type="text"
                    v-model.trim="product.middeL_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.middeL_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="surname">Lastname</label>

                  <InputText
                    id="surname"
                    type="text"
                    v-model.trim="product.surname"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.surname }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="aadhaR_CARD">Adhar No</label>
                  <InputText
                    id="aadhaR_CARD"
                    type="number"
                    v-model.trim="product.aadhaR_CARD"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.aadhaR_CARD }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.state }"
                  />
                </div>

                <div class="field col-12 md:col-2">
                  <label for="disrtict">District</label>
                  <InputText
                    id="disrtict"
                    type="text"
                    v-model.trim="product.disrtict"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.disrtict }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="taluka">Taluka</label>
                  <InputText
                    id="taluka"
                    type="text"
                    v-model.trim="product.taluka"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.taluka }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="citY_VILLAGE">City/Village</label>
                  <InputText
                    id="citY_VILLAGE"
                    type="text"
                    v-model.trim="product.citY_VILLAGE"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.citY_VILLAGE }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="number"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.pincode }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <label for="address">Address</label>

                  <Textarea
                    id="address"
                    type="text"
                    v-model.trim="product.address"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.address }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="mobile">Mobile No</label>
                  <InputText
                    id="mobile"
                    type="number"
                    v-model.trim="product.mobile"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.mobile,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model.trim="product.email"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.email }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <Button
              v-if="product.admiN_ID"
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              v-else
              label="ADD"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-danger mr-1 mb-1"
              @click="visibleFull = false"
            />
          </div>
        </Sidebar>

        <Dialog
          v-model:visible="add_not"
          :style="{ height: '100%', width: '90%' }"
          header="Send Notification"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <loading
              :active="isLoadingModel"
              :can-cancel="true"
              color="#274461"
              :is-full-page="fullPage"
            ></loading>

            <div class="col-12 md:col-12">
              <div class="field">Title</div>
              <div class="field">
                <InputText
                  placeholder="Notification Title"
                  v-model.trim="title"
                  required="true"
                  autofocus
                />
              </div>
              <div class="field">Description</div>
              <div class="field">
                <Textarea
                  placeholder="Notification Description"
                  :autoResize="true"
                  rows="3"
                  cols="30"
                  v-model.trim="description"
                />
              </div>
              <div class="field">Type</div>
              <div class="field">
                <InputText
                  placeholder="Notification Type"
                  v-model.trim="not_type"
                  required="true"
                  autofocus
                />
              </div>
              <div class="field">User Type</div>
              <div class="field">
                <MultiSelect
                  @change="get_user_list(1)"
                  v-model="user_type"
                  :options="multiselectValues"
                  optionLabel="name"
                  placeholder="Select user types"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div
                      class="
                        inline-flex
                        align-items-center
                        py-1
                        px-2
                        bg-primary
                        text-primary
                        border-round
                        mr-2
                      "
                      v-for="option of slotProps.value"
                      :key="option.code"
                    >
                      <!-- <span class="mr-2 " style="width:18px; height: 12px"/> -->
                      <div>{{ option.name }}</div>
                    </div>
                    <template
                      v-if="!slotProps.value || slotProps.value.length === 0"
                    >
                      <div class="p-1">Select User type</div>
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <!-- <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/> -->
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
              <div class="field"> Pincode</div>
              <div class="field">
                <MultiSelect
                  @change="get_user_list(2)"
                  v-model="pincode_type"
                  :options="pincode_array_filter"
                  optionLabel="name"
                  placeholder="Select Pincode"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div
                      class="
                        inline-flex
                        align-items-center
                        py-1
                        px-2
                        bg-primary
                        text-primary
                        border-round
                        mr-2
                      "
                      v-for="option of slotProps.value"
                      :key="option.code"
                    >
                      <!-- <span class="mr-2 " style="width:18px; height: 12px"/> -->
                      <div>{{ option.name }}</div>
                    </div>
                    <template
                      v-if="!slotProps.value || slotProps.value.length === 0"
                    >
                      <div class="p-1">Select Pincode</div>
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <!-- <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/> -->
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            <div class="field">User List</div>
              <div class="field">
                <MultiSelect
                  v-model="user_list"
                  :options="users_array_filter"
                  optionLabel="name"
                  placeholder="Select user types"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div
                      class="
                        inline-flex
                        align-items-center
                        py-1
                        px-2
                        bg-primary
                        text-primary
                        border-round
                        mr-2
                      "
                      v-for="option of slotProps.value"
                      :key="option.code"
                    >
                      <!-- <span class="mr-2 " style="width:18px; height: 12px"/> -->
                      <div>{{ option.name }}</div>
                    </div>
                    <template
                      v-if="!slotProps.value || slotProps.value.length === 0"
                    >
                      <div class="p-1">Select Users</div>
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <!-- <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/> -->
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
              <div class="field">
                <Button
                  type="button"
                  class="mr-2 mb-2"
                  label="Send Notification"
                  :loading="loadingssea[0]"
                  @click="send_notification(0)"
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.firsT_NAME }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteuser"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import JsonExcel from "vue-json-excel";
import BreadcrumbService from "../service/BreadcrumbService";

//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      user_type: [],
      pincode_type:[],
      title: "",
      description: "",
      not_type: "",
      users_array: [],
      users_array_filter: [],
      pincode_array_filter:[],
      user_list: [],
      loadingssea: [false, false, false],
      multiselectValues: [
        { name: "Commuter", code: "Rider" },
        { name: "Driver", code: "Driver" },
        { name: "Driver Owner", code: "Driver Owner" },
        { name: "Owner", code: "Owner" },
      ],
      // multiselectValues1: [
      // 	{name: 'Commuter', code: 'Rider'},
      // 	{name: 'Driver', code: 'Driver'},
      // 	{name: 'Driver Owner', code: 'Driver Owner'},
      // 	{name: 'Owner', code: 'Owner'}
      // ],
      page_no: 1,
      isLoading: false,
      search_mobile: "",
      from_date: "",
      to_date: "",
      isLoadingModel: false,
      add_not: false,
      loading: false,
      fullPage: true,
      file_attachment: "",
      // dropdownItems: [
      //   { name: "Motivational", value: "Motivational" },
      //   { name: "Interview", value: "Interview" },
      // ],
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      ride_status: "",
      ride_status_dropdown: [{ name: "Read", value: "1" },
                             {name: "UnRead", value: "0"},
                             
                            ],
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],

      BreadcrumbService : null,
breadcrumbHome: {},
breadcrumbItems: [],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();

    this.BreadcrumbService = new BreadcrumbService();
	let Breadcrum_data = this.BreadcrumbService.pushnotificationmaster()
	this.breadcrumbHome=Breadcrum_data.home;
	this.breadcrumbItems=Breadcrum_data.items;
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    filter_user() {
      alert(document.getElementById("userlist").value);

      // for(i=0;i<=this.users_array;i++)
      // {
      //   // if(this.users_array[i].mobile_number.includes())
      // }
    },
    get_user_list(flag) {
      var user_type = [];
      var pincode_type = [];
      for (var i = 0; i < this.user_type.length; i++) {
        user_type.push(this.user_type[i].code);
      }
      for (var z = 0; z < this.pincode_type.length; z++) {
        pincode_type.push(this.pincode_type[z].code);
      }
      var data = {
        count: false,
        user_type_array: user_type,
        pincode_array: pincode_type
      };
      this.isLoadingModel = true;
      var promise = apis.userlist(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.users_array = response.data.data;

        this.pin_array = response.data.data;
        const filteredData = this.pin_array.filter(item => item.pincode !== '');
        
        if (flag==1) 
        {
          this.pincode_array_filter = [];

          for (var z = 0; z < filteredData.length; z++) 
          {
            this.pincode_array_filter.push({
              name:filteredData[z].pincode,
              code:filteredData[z].pincode,
            
            });
          }
        }
        

      this.users_array_filter = [];
        for (var i = 0; i < this.users_array.length; i++) {
          this.users_array_filter.push({
            name:
              this.users_array[i].mobile_number +
              (this.users_array[i].first_name
                ? " - " + this.users_array[i].first_name
                : "") +
              (this.users_array[i].last_name
                ? "  " + this.users_array[i].last_name
                : ""),
            code:
              this.users_array[i].mobile_number +
              (this.users_array[i].first_name
                ? " - " + this.users_array[i].first_name
                : "") +
              (this.users_array[i].last_name
                ? "  " + this.users_array[i].last_name
                : ""),
            value: this.users_array[i].mobile_number,
          });
        }
        // this.users_array_filter=response.data.data;
      });
    },
    async send_notification(index) {
      if (this.title == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Title",
          life: 3000,
        });
      } else if (this.description == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Description",
          life: 3000,
        });
      } else if (this.not_type == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Notification Type",
          life: 3000,
        });
      } else {
        var user_type = [];
        for (var i = 0; i < this.user_type.length; i++) {
          user_type.push(this.user_type[i].code);
        }
        var user_list = [];
        for (var i = 0; i < this.user_list.length; i++) {
          user_list.push(this.user_list[i].value);
        }
        // console.log(user_list);
        // return;
        this.loadingssea[index] = true;
        var data = {
          title: this.title,
          description: this.description,
          type: this.not_type,
          status: this.ride_status.value,
          userType: user_type,
          user_list: user_list,
        };
        console.log(data);
        return;
        var promise = apis.adminsendpushnotification(data, this.id);
        promise.then((response) => {
          this.loadingssea[index] = false;
          this.title = "";
          this.description = "";
          this.not_type = "";
          this.user_type = [];
          this.get_list();
          this.get_count();
          this.$swal.fire(response.data.message);
          this.add_not = false;
        });
      }
    },
    add_notification() {
      this.add_not = true;
      // alert('here');
    },
    search(count) {
      if (count==2) 
        {
            this.from_date='';
            this.to_date='';
            this.search_mobile='';
            this.ride_status='';


        }
        if(this.search_mobile!='' && this.search_mobile.length!=10)
        {
          this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Valid Mobile Number",
          life: 3000,
        });
        return false;
        }
      this.page_no = 1;
      this.get_list();
      this.get_count();
    },
    //a simple date formatting function
    dateFormat(inputDate) {
      var postdate = inputDate;
      if (inputDate) {
        inputDate = new Date(inputDate);
        if (inputDate.toString() == "NaN-NaN-NaN") {
          return postdate;
        } else {
          let date = ("0" + inputDate.getDate()).slice(-2);

          // current month
          let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);

          // current year
          let year = inputDate.getFullYear();
          if (date.toString() == "aN") {
            return postdate;
          } else {
            return date + "-" + month + "-" + year;
          }
        }
      } else {
        return "";
      }
    },
    dateFormatYMD(inputDate) {
      var postdate = inputDate;
      if (inputDate) {
        inputDate = new Date(inputDate);
        if (inputDate.toString() == "NaN-NaN-NaN") {
          return postdate;
        } else {
          let date = ("0" + inputDate.getDate()).slice(-2);

          // current month
          let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);

          // current year
          let year = inputDate.getFullYear();
          if (date.toString() == "aN") {
            return postdate;
          } else {
            return year + "-" + month + "-" + date;
          }
        }
      } else {
        return "";
      }
    },
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        id: localStorage.getItem("id"),
        count: true,
        unread: false,
        mobile_number: this.search_mobile,
        status: this.ride_status.value,
        from_date: this.dateFormatYMD(this.from_date),
        to_date: this.dateFormatYMD(this.to_date),
        ntype: "ride sos alert",
      };
      this.loading = true;
      var promise = apis.sosAlertMaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        status: this.ride_status.value,
        mobile_number: this.search_mobile,
        from_date: this.dateFormatYMD(this.from_date),
        to_date: this.dateFormatYMD(this.to_date),
        ntype: "ride sos alert",
      };
      this.loading = true;
      var promise = apis.sosAlertMaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async adduser() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000,
        });
        return false;
      }
      if (!this.product.firsT_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter First Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.middeL_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Middle Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.surname) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter LastName",
          life: 3000,
        });
        return false;
      }
      if (!this.product.aadhaR_CARD) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Adhar No",
          life: 3000,
        });
        return false;
      }

      if (!this.product.state) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter State",
          life: 3000,
        });
        return false;
      }
      if (!this.product.disrtict) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter District",
          life: 3000,
        });
        return false;
      }
      if (!this.product.taluka) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Taluka",
          life: 3000,
        });
        return false;
      }
      if (!this.product.citY_VILLAGE) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter city/village",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pincode) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pincode",
          life: 3000,
        });
        return false;
      }
      if (!this.product.address) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter address",
          life: 3000,
        });
        return false;
      }
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter mobile",
          life: 3000,
        });
        return false;
      }
      if (!this.product.email) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter email",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.admiN_ID) {
        var data = {
          Admin_id: this.product.admiN_ID,
          firsT_NAME: this.product.firsT_NAME,
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          disrtict: this.product.disrtict,
          taluka: this.product.taluka,
          citY_VILLAGE: this.product.citY_VILLAGE,
          mobile: parseInt(this.product.mobile),
          email: this.product.email,
          pincode: parseInt(this.product.pincode),
          aadhaR_CARD: parseInt(this.product.aadhaR_CARD),
          status: this.selectstatus.value,
          createD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promise = apis.editusermaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          firsT_NAME: this.product.firsT_NAME,
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          disrtict: this.product.disrtict,
          taluka: this.product.taluka,
          citY_VILLAGE: this.product.citY_VILLAGE,
          mobile: parseInt(this.product.mobile),
          email: this.product.email,
          pincode: parseInt(this.product.pincode),
          aadhaR_CARD: parseInt(this.product.aadhaR_CARD),
          status: this.selectstatus.value,
          createD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promises = apis.addusermaster(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      //  this.selectstatus2={name:"",value:""}
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachment = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product._id) {
        var data = {
          _id: this.product._id,
        };
        this.isLoadingModel = true;
        var promise = apis.deleteusermaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },

    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: { downloadExcel: JsonExcel },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
